<template>
  <div class="CompleteProfile">
    <div class="CompleteProfile--column p-sm">
      <h1>{{$t('profile_personalization')}}</h1>
    </div>
    <form class="Form" @submit.prevent="handleSubmit">
      <div class="Form__Container">
        <div class="Form__Container--Required">
          <ProfilePicture
            :picture="editForm.picture"
            :isButton="true"
            @photo-saved="savePhoto"
          />
        </div>
        <span class="v-space"></span>
        <div class="CompleteProfile--column">
          <text-input
            id="email"
            :label="$t('profile_personalization_email')"
            v-model="editForm.email"
            :disabled="true"
            whiteBg
          />
          <select-input
            id="gender"
            :label="$t('profile_personalization_gender')"
            :selected="String(editForm.gender)"
            :value="[
              { id: 'M', value: $t('gender_M')    },
              { id: 'W', value: $t('gender_W')  }
            ]"
            v-on:change="handleChangeGender"
            whiteBg
          />
          <date-input
            id="bornDate"
            :label="$t('profile_personalization_borndate')"
            v-model="editForm.bornDate"
            whiteBg
            :yearRange="validBornDates">
          </date-input>
          <select-input
            :label="$t('profile_personalization_province')"
            id="province"
            :selected="String(editForm.province.id)"
            :value="provinces"
            @change="handleChangeProvince"
            whiteBg
          />
        </div>
        <text-input
            v-show="theme.active2fa"
            id="mobile"
            type="number"
            :label="$t('profile_personalization_mobile')"
            v-model="editForm.mobile"
            whiteBg
          />

        <span class="v-space"></span>
        <form-errors :showErrors="errorLog" class="Form--alerts">
          {{ errorLog }}
        </form-errors>
        <form-success :showSuccess="formSuccess" class="Form--alerts">
          {{ formSuccess }}
        </form-success>
        <span class="v-space"></span>

        <div class="Form__Container--acceptance">
          <div>
            <input type="checkbox" name="ch-acceptance" v-model="editForm.participation">
            <label for="ch-acceptance" v-if="this.acceptance != '' || this.acceptance != undefined">{{this.acceptance}}</label>
            <label for="ch-acceptance" v-if="this.acceptance == '' || this.acceptance == undefined">{{$t('signup_acceptance_default')}}</label>
          </div>
          <div>
            <input type="checkbox" name="ch-conditions" v-model="editForm.conditions">
            <label for="ch-conditions">  {{$t('signup_conditions1')}}
              <router-link :to="{ name: 'conditions' }" target="_blank">{{$t('signup_conditions2')}}</router-link>
            </label>
          </div>
        </div>

        <div class="CompleteProfile--column p-sm align-right">
          <Button
            color="accent"
            :disabled="invalidForm"
            @click="handleSubmit"
          > {{$t('save')}} <Spinner v-show="loading" />
          </Button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import TextInput                from '@/components/TextInput.vue';
  import TextInputWithLoader      from '@/components/TextInputWithLoader.vue';
  import SelectInput              from '@/components/SelectInput.vue';
  import Button                   from '~/components/Button';
  import FormErrors               from '@/components/FormErrors.vue';
  import FormSuccess              from '@/components/FormSuccess.vue';
  import Spinner                  from '~/components/Spinner.vue';
  import ProfilePicture           from '@/components/ProfilePicture.vue';
  import DateInput                from '@/components/DateInput.vue'
  import logo                     from '@/assets/img/logo.svg';
  import moment                   from 'moment';
  import store                    from '@/store';
  import { mapGetters }           from 'vuex';
  import { DEBOUNCE_SPEED }       from '~/constants/constants.type';
  import {
    fetchUserProfile,
    isNicknameAvailable
  }  from '@/utils';

  import {
    USER_PUT_PROFILE_CHANGES,
    USER_FETCH_PROFILE,
  } from '@/store/user.module.ts';
  import {
    MISC_FETCH_PROVINCES,
    MISC_FETCH_COUNTRY_CODE
  } from '@/store/misc/provinces.module';
  import {
    COMMUNITY_FETCH_CONDITIONS
  } from '@/store/community/info.module';

  export default {
    name: 'ProfilePage',

    components: {
      TextInput,
      TextInputWithLoader,
      SelectInput,
      Button,
      FormErrors,
      FormSuccess,
      Spinner,
      ProfilePicture,
      DateInput
    },

    data() {
      return {
        editForm      : {
          email     : "",
          gender    : "",
          bornDate  : "",
          province  : {},
          picture   : "",
          medals    : { bronce: 0, gold: 0, silver: 0 },
          mobile: null,
        },
        acceptance    : "",
        errorLog      : "",
        loading       : "",
        formSuccess   : '',
        reqDelay: null
      };
    },

    computed: {
      ...mapGetters([
        'userProfile',
        'provinces',
        'theme',
        'origin',
        'communityInfo'
      ]),

      invalidForm ( ) {
        return Boolean(
          !this.editForm.participation ||
          !this.editForm.conditions ||
          !this.editForm.bornDate ||
          !this.validateMobile() ||
          this.errorLog
        )
      },

      svgBackground ( ) {
        return logo;
      },

      validBornDates(){
        const date = new Date();
        const year = date.getFullYear();
        const limit_old = year - 100;
        const limit_young = year - 16;
        return [limit_old, limit_young]
      }
    },

    methods: {
      validateMobile(){
        if(!this.theme.active2fa) return true
        if(this.editForm.mobile == null) return false
        const numberStr = String(this.editForm.mobile)
        if(numberStr.length < 9) return false
        return true
      },
      handleChange ( value, id, event ) {
        if ( event != undefined && event.key === 'Tab' ) {
          this.editForm[id] = value;
          return;
        }

        if ( this.reqDelay ) clearTimeout( this.reqDelay );

        this.reqDelay = setTimeout( ( ) => {
          this.editForm[id] = value
        }, DEBOUNCE_SPEED );
      },
      handleChangeGender(value, id, event){
        if(value != 'M' && value != 'W') return
        this.editForm.gender = value
      },

      handleChangeProvince ( value, id ) {
        if (isNaN(value)) return
        this.editForm.province = {
          elementId: value,
          id: value,
          lovName: "provinces"
        }
      },

      handleChangeNickname ( event, id ) {
        isNicknameAvailable( event, this );
      },

      handleDateInput ( e ) {
        this.editForm.bornDate = e;
      },

      async handleSubmit ( e ) {
        if ( !this.errorLog )
          this.sendUpdateRequest( );
      },

      savePhoto ( path ) {
        this.editForm.picture = path;
        this.showUploader = false;
      },

      sendUpdateRequest ( ) {
        let bornDate = null
        let stringBornDate = JSON.stringify(this.editForm.bornDate).toString()
        if (stringBornDate.slice(stringBornDate.length - 2) != 'Z"') {
          bornDate = this.editForm.bornDate.replace(/\+\d{4}/, "Z");
        } else {
          bornDate = this.editForm.bornDate;
        }
        if (this.editForm.surname1 == "" || this.editForm.surname1 == null) this.editForm.surname1 = "-"
        this.loading = true;
        store.dispatch( USER_PUT_PROFILE_CHANGES, {
          response: { ...this.editForm, bornDate: bornDate }
        } )
          .then( async ( data ) => {
            this.formSuccess  = this.$t('success_profile_updated');
            this.loading      = false;
            await store.dispatch( USER_FETCH_PROFILE );
            this.$router.push( { name: 'welcome', params: { origin: this.$route.params.origin } } );
          } )
          .catch( error => {
            this.loading      = false;
            this.errorLog     = error;
            if (error.response.data != null || error.response.data != undefined) {
              const errorMessage = error.response.data.error; 
              const duplicatedField = error.response.data.duplicatedKey;
              if (duplicatedField == 'nick' && errorMessage == 'duplicate element') {
                this.errorLog = this.$t('error_nickname_duplicated');
              }
            }
          } );

      },

      fetchConditions() {
        store.dispatch( COMMUNITY_FETCH_CONDITIONS)
          .then( async ( data ) => {
            this.acceptance = data.acceptance
          } )
      },

      bornDateIsValid(userDate) {
        const date = new Date();
        const year = date.getFullYear();
        const limit_old = year - 100;
        const limit_young = year - 16;
        const bornDate = new Date(userDate);
        const bornYear = bornDate.getFullYear();
        if (bornYear <= limit_old || bornYear >= limit_young) {
            return false;
        } else {
            return true;
        }
      }
    },

    async created ( ) {
      await store.dispatch(MISC_FETCH_COUNTRY_CODE);
      await store.dispatch(MISC_FETCH_PROVINCES);
      fetchUserProfile( this, true);
      this.fetchConditions()
      await store.dispatch( USER_FETCH_PROFILE,{complete:true} )
      .then((data) => {
        this.editForm.email = data.data.email
        this.editForm.gender = "M"
        this.editForm.province = {lovName:"provinces",id:0,elementId:0}
        this.editForm.surname1 = data.data.surname1 ?? "-"; //required
        this.editForm.name = data.data.name ?? "-"; //required
      })
      if (this.editForm.bornDate != null && this.editForm.bornDate != "") {
        if (!this.bornDateIsValid(this.editForm.bornDate)) {
          this.editForm.bornDate = ""
        }
      }
    }

  }
</script>
<style lang="scss">
.CompleteProfile{
  input[type="text"],select {
    height        : 40px;
    margin-top    : 5px;
    border        : none;
    outline       : none;
    box-shadow    : 0px 0px 20px 0px rgba( 0, 0, 0, 0.1 );
    transition    : box-shadow 300ms;
    font-size     : 1rem!important;

    &:focus {
      box-shadow  : 0px 0px 20px 0px rgba( 0, 0, 0, 0.3 );
    }
  }
  select option{
    font-size: 1rem;
  }
}

</style>
<style scoped lang="scss">
  .CompleteProfile {
    width                   : 100%;
    &--column {
      display               : flex;
      flex-direction        : row;
      flex-wrap             : wrap;
    }

    h1 {
      padding               : 1rem 60px;
    }

    .Form {
      padding               : 1rem 60px;
      background            : #edededa1;

      &--alerts {
        width               : auto;
        margin              : 0 .5rem;
      }

      &__Container {
        width               : 80%;

        &--Required {
          display           : flex;
          align-items       : flex-end;
        }

        &--acceptance{
          margin: 10px 0;
          margin-left: 8px;
          div{
            display: flex;
            align-items: baseline;
            margin: 10px 0;
            input{
              position:relative;
              margin-right: 5px;
              outline: none;
            }
            input::before{
              height:15px;
              width: 15px;
              border:1px solid var(--primary-color);
              color: transparent;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content:'\f00c';
              position: absolute;
              background: white;
              cursor:pointer;
            }
            input:checked::before{
              color: var(--primary-color);
            }
          }
        }
      }
    }
    .p-sm {
      padding               : .5rem;
    }

    .v-space {
      padding               : .5rem 0;
    }

    .align-right {
      justify-content       : flex-end;
    }
  }

  @media screen and ( max-width: 768px ) {
    .CompleteProfile {
      &--column {
        flex-direction      : column;
      }

      .Form {
        padding             : 20px !important;
        background          : #edededa1;

        &__Container {
          width             : 100% !important;

          &--Required {
            flex-direction  : column;
            justify-content : center;
            align-items     : center !important;
          }
        }
      }
    }
  }

  @media screen and ( max-width: 575px ) {
    .CompleteProfile {
      width                 : 100%;
    }
  }
</style>
