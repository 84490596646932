<template>
  <div class="TextInputWithLoader">
    <label :for="id"  v-text="label"></label>
    <div class="Input">
      <input
        :id="id"
        type="text"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="whiteBg && 'white-bg'"
        :style="error && 'border-bottom: 3px solid red'"
        @keyup="$emit( 'typing', $event, id )"
        @keydown="$emit( 'typing', $event, id )"
        >
      <spinner dark class="icon" v-if="loading" />
    </div>
  </div>
</template>

<script>
import Spinner from '~/components/Spinner.vue';

export default {
  name: 'TextInputWithLoader',
  components: {
    Spinner
  },
  props: {
    label       : String,
    value       : String,
    id          : String,
    disabled    : Boolean,
    placeholder : String,
    dark        : Boolean,
    whiteBg     : Boolean,
    loading     : Boolean,
    error       : String
  },
}
</script>

<style lang="scss" scoped>
  .TextInputWithLoader {
    padding             : .5rem;
    width               : 100%;
    position            : relative;

    @media screen and ( max-width: 768px ) {
      width             : 100%;
    }

    label {
      text-transform    : uppercase;
      font-weight       : 600;
      font-size         : .8rem;
      padding-bottom    : 5px;
    }

    .white-bg {
      background        : white !important;
    }

    .Input {
      position          : relative;
      padding-top       : 5px;
      
      .icon {
        position        : absolute;
        right           : 0;
        padding         : 10px;
        min-width       : 37px;
        text-align      : center;
        height          : 100%;
      }

      input {
        background      : #f4f4f4;
        border          : 1px solid #e4e4e4;
        font-size       : 1.2rem;
        padding         : .5rem 1rem;
        width           : 100%;
        transition      : border 300ms; 

        &:focus {
          outline       : none;
        }
      }
    }
  }
</style>
